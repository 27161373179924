<template>
  <div class="violations-modal-create">
    <div class="violations-modal-create__block">
      <r-text>Вид нарушения</r-text>
      <el-select
        v-model="violationType"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип"
      >
        <el-option
          v-for="item in violationTypes"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
      <div class="violations-modal-create__row">
        <el-switch
          v-model="isEditorOdd"
          :disabled="oddDisabled"
          class="r-switch"
        />
        <r-text color-type="secondary">
          Выбрать объект из Редактора ОДД
        </r-text>
      </div>
    </div>

    <div
      v-if="isEditorOdd"
      class="violations-modal-create__block"
    >
      <r-text>Событие из редактора ОДД</r-text>
      <el-select
        v-model="oddObjectId"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип"
      >
        <el-option
          v-for="item in events"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>

    <div
      v-if="isAccident && oddObjectId"
      class="violations-modal-create__block"
    >
      <r-text>Нарушитель</r-text>
      <el-select
        v-model="intruderId"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип"
      >
        <el-option
          v-for="item in intruders"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>

    <r-button
      type="primary"
      class="violations-modal-create__button"
      :disabled="disabled"
      :loading="loading"
      @click="createViolation"
    >
      Подтвердить
    </r-button>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { accidentTypes } from './configs'
import { sources } from '../../configs'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      violationType: null,
      isEditorOdd: false,
      oddObjectId: null,
      intruderId: null,
      sources
    }
  },
  computed: {
    violationTypes() {
      return this.source?.related?.type?.data || []
    },
    isAccident() {
      if (!this.violationType) return false

      const violationName = this.violationTypes.find(
        e => e.id === this.violationType
      )?.name

      return accidentTypes.includes(violationName)
    },
    isClosure() {
      if (!this.violationType) return false

      const violationId = this.violationTypes.find(
        e => e.name === 'Нарушение УДС'
      )?.id

      return this.violationType === violationId
    },
    intruders() {
      return this.events.find(
        e => e.id === this.oddObjectId
      )?.trafficaccident?.intruders || []
    },
    events() {
      if (this.isAccident) {
        return this.source?.related?.event?.data.filter(
          e => e.event_class?.name === 'ДТП'
        )
      } else {
        return this.source?.related?.event?.data.filter(
          e => e.event_class?.name === 'Недостатки'
        )
      }
    },
    oddDisabled() {
      return !this.isClosure && !this.isAccident
    },
    disabled() {
      return !this.violationType
    }
  },
  watch: {
    violationType(val) {
      if (!val) {
        this.isEditorOdd = false
      }
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      this.oddObjectId = null
      this.intruderId = null
    }
  },
  methods: {
    async createViolation() {
      this.loading = true

      try {
        const data = {
          type_id: this.violationType
        }
        if (this.oddObjectId) {
          data.event_id = this.oddObjectId
        }
        if (this.intruderId) {
          data.intruder_id = this.intruderId
        }
        const { data: response } = await this.$store.dispatch('POST_REQUEST', {
          url: 'BddViolation',
          data
        })
        const violationId = response?.id

        const message = 'Сохранение выполнено успешно'

        this.$notify(notifyFactory('succcess', message))
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })

        this.$store.commit('CLOSE_MODAL_WINDOW')
        this.$store.commit('BDD_SET_FIELD', {
          field: 'openAfterUpdate',
          value: violationId
        })
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.violations-modal-create {
  display: grid;
  grid-gap: 1rem;
  align-content: start;

  &__block {
    display: grid;
    grid-gap: 0.5rem;
    align-content: start;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    align-items: center;
  }

  &__button {
    justify-self: start;
  }
}
</style>
